<template>
    <div>
        <Navigation/>
        <div
    class="d-flex align-items-center justify-content-center"
    style="height: 80vh"
  >
    <h1>Verify your email address to login</h1>
  </div>
    </div>
  
</template>

<script>
import Navigation from "../../components/navigation.vue"
export default {
    components: {
        Navigation
    }
}
</script>

<style>

</style>